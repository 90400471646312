.footer4-footer7 {
  background-color: #fff; /* White background */
  padding: 40px 20px 20px; /* Adjust padding */
  width: 100%; /* Full width */
}

.footer4-content {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%; /* Full width */
}

.footer4-sections {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px; /* Limit the width to prevent stretching */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.footer4-contact,
.footer4-follow-us {
  width: 30%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items in each section */
}

.footer4-contact h4,
.footer4-follow-us h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333; /* Dark color for headings */
  text-transform: uppercase;
  text-align: center;
}

.footer4-title-bar {
  width: 50%;
  height: 2px;
  background-color: #2caa46; /* Dark blue bar */
  margin: 10px auto; /* Center the horizontal bar */
}

.footer4-contact-item,
.footer4-social-item {
  display: flex;
  align-items: center; /* Vertical alignment of image and text */
  margin-bottom: 10px;
  justify-content: center; /* Center the content horizontally */
}

.footer4-contact-item img,
.footer4-social-item img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.footer4-contact-item span,
.footer4-social-item a {
  font-size: 14px;
  color: #333; /* Dark text color */
}

.footer4-contact-item span:hover,
.footer4-social-item a:hover {
  color: #2caa46; /* Highlight color */
  text-decoration: none;
}

.footer4-divider {
  height: 100%;
  width: 2px;
  background-color: #2caa46; /* Dark blue line */
  margin: 0 30px;
}

.footer4-follow-us a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
}

.footer4-follow-us a:hover {
  color: #2caa46;
  text-decoration: none; /* No underline */
}

.footer4-logo-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%; /* Set width of the logo container */
}

.footer4-logo-image {
  width: 180px; /* Logo size */
  height: auto;
}

.footer4-large-line {
  height: 2px;
  background-color: #000000c3; /* Dark blue line */
  width: 100%;
  margin-top: 20px;
}

.footer4-credits {
  margin-top: 20px;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  color: #555; /* Lighter color for copyright */
}

.footer4-phone-link {
  font-size: 14px;
  color: #333; /* Dark text color */
  text-decoration: none; /* Remove underline */
}

.footer4-phone-link:hover {
  color: #2caa46; /* Dark blue color on hover */
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .footer4-sections {
    flex-direction: column; /* Stack sections vertically */
    align-items: center;
  }

  .footer4-contact,
  .footer4-follow-us,
  .footer4-logo-center {
    width: 100%; /* Full width on mobile */
    margin-bottom: 30px;
    align-items: center; /* Center content for each section */
  }

  .footer4-logo-image {
    width: 150px; /* Adjust logo size for mobile */
  }

  .footer4-title-bar {
    width: 60%; /* Adjust title bar width */
  }

  .footer4-divider {
    margin: 20px 0; /* Adjust divider for mobile view */
  }

  .footer4-contact-item,
  .footer4-social-item {
    flex-direction: column; /* Stack the icons and text vertically on mobile */
    align-items: center;
    margin-bottom: 15px; /* Increase space between items */
  }

  .footer4-contact-item img,
  .footer4-social-item img {
    margin-right: 0; /* Remove margin to align images properly */
    margin-bottom: 8px; /* Add space between icon and text */
  }

  .footer4-contact-item span,
  .footer4-social-item a {
    font-size: 16px; /* Increase font size slightly for better readability on mobile */
  }

  .footer4-large-line {
    width: 100%; /* Ensure large line is full width on mobile */
  }

  .footer4-credits {
    font-size: 12px; /* Make copyright text smaller for mobile */
  }
}
