/* General layout and spacing adjustments */
.reserve-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: 'Arial', sans-serif; /* Clean font for the page */
  }
  
  .reserve-section {
    background-color: #fff;
    padding: 4rem 0;
    flex: 1;
  }
  
  .reserve-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 1rem;
    text-align: center;
  }
  
  .reserve-header {
    margin-bottom: 2.5rem; /* Increased space below the header */
  }
  
  .reserve-title {
    font-size: 2.25rem; /* Slightly larger title */
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 0.5rem; /* Space between title and description */
  }
  
  .reserve-description {
    font-size: 1.125rem;
    color: #6b7280;
    margin-bottom: 2rem;
  }
  
  .reserve-form {
    background-color: #f9fafb;
    padding: 2.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    margin: 0 auto; /* Centering the form */
    text-align: left; /* Align form fields to the left */
  }
  
  .reserve-row {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 1.5rem; /* Space between rows of input fields */
  }
  
  @media (min-width: 640px) {
    .reserve-row {
      flex-direction: row;
      gap: 1.5rem; /* More space between fields on larger screens */
    }
  }
  
  .reserve-field {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .reserve-label {
    display: block;
    font-size: 1rem; /* Larger font for labels */
    font-weight: 600; /* Bold labels for clarity */
    color: #374151;
    margin-bottom: 0.75rem; /* Increased spacing below the label */
    text-align: left; /* Align label to the left */
  }
  
  .reserve-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    font-size: 1rem;
    color: #374151;
    outline: none;
    transition: border-color 0.2s ease-in-out;
    margin-bottom: 1.25rem; /* More space between inputs */
  }
  
  .reserve-input:focus {
    border-color: #2caa46; /* Blue border on focus */
  }
  
  /* Button styling for the "Réserver Maintenant" button */
  .reserve-button {
    background-color: #2caa46; /* Blue background color */
    color: white; /* White text */
    font-size: 1.125rem; /* Larger text size */
    padding: 12px 24px; /* Vertical and horizontal padding */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
    width: 100%; /* Full width to align with the form container */
    max-width: 250px; /* Optional: limit the max width of the button */
    margin-top: 2rem; /* Add more space above the button */
  }
  
  .reserve-button:hover {
    background-color: #2caa46; /* Darker blue on hover */
    transform: scale(1.05); /* Slight scale effect for hover */
  }
  
  .reserve-button:active {
    background-color: #2caa46; /* Even darker blue when clicked */
    transform: scale(0.98); /* Slight shrink effect on click */
  }
  
  .reserve-button:disabled {
    background-color: #cccccc; /* Disabled button color */
    cursor: not-allowed; /* Change cursor to not-allowed when disabled */
  }
  