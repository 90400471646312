/* General styling */
.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
  }
  
  .contact-content {
    margin-top: 160px;
    width: 100%;
    max-width: 600px;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Title and description */
  .contact-title {
    font-size: 2rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }
  
  .contact-description {
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 1.5rem;
  }
  
  /* Form styling */
  .form-container {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form .form-group {
    margin-bottom: 1.25rem;
  }
  
  .form-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #34495e;
    margin-bottom: 0.5rem;
  }
  
  .form-input {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    color: #34495e;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .form-input:focus {
    border-color: #2caa46;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
  }
  
  /* Submit button */
  .form-action {
    display: flex;
    justify-content: center;
  }
  
  .submit-button {
    background-color: #2caa46;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #2caa46;
  }
  
  /* Responsive styling */
  @media (max-width: 600px) {
    .contact-content {
      padding: 1.5rem;
    }
  
    .contact-title {
      font-size: 1.75rem;
    }
  
    .contact-description {
      font-size: 0.875rem;
    }
  }
  