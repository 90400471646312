/* Main container styling */
.service-container {
  background-color: #f9fafb;
  font-family: Arial, sans-serif;
}

/* Service Intro Section */
.service-intro {
  padding: 2rem 1.5rem;
  text-align: center; /* Center the intro text */
}

.service-intro .container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content */
  gap: 1.5rem; /* Reduce spacing */
}

.service-intro h1 {
  font-size: 1.8rem; /* Smaller heading */
  color: #1f2937;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.service-intro p {
  font-size: 1rem; /* Smaller paragraph text */
  line-height: 1.5rem;
  color: #4b5563;
}

/* Responsive Layout */
@media (min-width: 768px) {
  .service-intro .container {
      align-items: center;
      text-align: center; /* Keep content centered on larger screens */
  }
}

/* Services List Section */
.services-list {
  background-color: #ffffff;
  padding: 2rem 1rem;
  text-align: center; /* Center the list */
}

.services-list ul {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Reduce spacing */
  align-items: center; /* Center the list items */
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items */
  text-align: center;
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 600px;
  width: 100%;
}

.service-item:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.service-item img.service-icon {
  width: 50px; /* Adjust the size of the icons */
  height: 50px;
  margin-bottom: 0.5rem; /* Add spacing between the icon and the title */
}

.service-item h3 {
  font-size: 1.2rem; /* Smaller heading */
  color: #1f2937;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.service-item p {
  font-size: 0.9rem; /* Smaller paragraph text */
  color: #4b5563;
  line-height: 1.4;
}

/* Footer Spacing */
footer {
  margin-top: 3rem;
}
