.testimonial17-title {
  font-size: 2rem;
  margin-bottom: var(--dl-space-space-twounits);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--dl-color-theme-accent2);
  text-align: center;
}

.testimonial17-certificat {
  margin-bottom: var(--dl-space-space-threeunits);
}

.testimonial17-certificat-images {
  display: flex;
  justify-content: center;
  gap: var(--dl-space-space-fourunits);
}

.testimonial17-certificat-image {
  width: 45%; /* Adjust the width of each image */
}

.testimonial17-certificat-image img {
  width: 100%;
  height: auto;
  border-radius: var(--dl-radius-radius-cardradius);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.testimonial17-images {
  display: flex;
  justify-content: center;
  gap: var(--dl-space-space-threeunits);
}

.testimonial17-main-image img {
  width: 100%;
  height: auto;
  border-radius: var(--dl-radius-radius-cardradius);
}

.testimonial17-secondary-images {
  display: flex;
  gap: var(--dl-space-space-twounits);
}

.testimonial17-secondary-image img {
  width: 100%;
  height: auto;
  border-radius: var(--dl-radius-radius-cardradius);
}
