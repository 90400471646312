.hero17-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.hero17-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.hero17-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.hero17-text1 {
  text-align: center;
}
.hero17-text2 {
  text-align: center;
}
.hero17-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
}
.hero17-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.hero17-row-container1 {
  width: 100%;
}
.hero17-placeholder-image10 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image11 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image12 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image13 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image14 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image15 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image16 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image17 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image18 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image19 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image20 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image21 {
  width: 400px;
  height: 400px;
}
.hero17-row-container2 {
  width: 100%;
}
.hero17-placeholder-image22 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image23 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image24 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image25 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image26 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image27 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image28 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image29 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image30 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image31 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image32 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image33 {
  width: 400px;
  height: 400px;
}
.hero17-container2 {
  display: contents;
}
.hero17-text5 {
  display: inline-block;
}
.hero17-text6 {
  display: inline-block;
}
.hero17-text7 {
  display: inline-block;
}
.hero17-text8 {
  display: inline-block;
}
@media(max-width: 767px) {
  .hero17-content2 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .hero17-actions {
    width: 100%;
    flex-direction: column;
  }
  .hero17-button1 {
    width: 100%;
  }
  .hero17-button2 {
    width: 100%;
  }
}
.hero17-wrapper {
  text-align: center;
  margin-bottom: 20px; /* Add some spacing between title and slider */
}

.hero17-title {
  font-size: 48px;
  color: black;
  font-weight: bold;
}

.hero17-container {
  position: relative;
  width: 1600px;
  height: 500px;
  margin: auto;
  overflow: hidden;
}

.hero17-slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hero17-slides {
  display: flex;
  width: 400%; /* 3 slides * 100% */
  height: 100%;
  animation: slide 180s linear infinite; /* Slow animation (180 seconds) */
}

.hero17-slide {
  width: 1600px;
  height: max-content ;
  flex-shrink: 0;
}
.hero17-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Keep this for desktop view, ensures the images cover the slide area */
}

.hero17-slides .hero17-slide:nth-child(1) {
  animation-delay: 0s;
}

.hero17-slides .hero17-slide:nth-child(2) {
  animation-delay: -60s;
}

.hero17-slides .hero17-slide:nth-child(3) {
  animation-delay: -120s;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-200%);
  }
  75% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Title Styling */
.hero17-title {
    font-size: 2rem; /* Adjust size */
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

/* Paragraph Styling */
.hero-container p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    max-width: 100%;
    margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero17-title {
    font-size: 1.5rem; /* Adjust font size for mobile */
  }

  .hero-container p {
    font-size: 1rem;
  }

  .hero17-slider {
    margin-top: 50px;
    height: 100%; /* Allow slider height to adjust based on content */
  }

  .hero17-slide {
    height: auto;
  }

  .hero17-slide img {
    object-fit: contain; /* Change to 'contain' on mobile to prevent cutting off */
    height: auto; /* Maintain aspect ratio without stretching */
  }

  .hero17-slides {
    width: 300%; /* Adjust slides width to maintain three slides on mobile */
  }
}

.gradual-lines {
  display: block;
  text-align: center;
}

.line1 {
  display: block;
  width: 100%;
  text-align: center;
}

.line2 {
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.line3 {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.line1,
.line2,
.line3 {
  margin-bottom: 10px;
}
