.navbar8-container1 {
  top: 0;
  width: 100%;
  height: 140px;
  display: flex;
  z-index: 1000;
  position: sticky;
  justify-content: center;
  margin-bottom: -150px;
  background-color: var(--dl-color-theme-neutral-light);
}.navbar8-image1 {
  margin-top: 20px; /* Adjust this value to move the logo down */
}
.navbar8-buttons1 {
  margin-top: 30px; /* Moves the buttons 30px down */
}

.navbar8-navbar-interactive {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}

.navbar8-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar8-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.navbar8-link11 {
  text-decoration: none;
}
.navbar8-link31 {
  text-decoration: none;
}
.navbar8-link4-dropdown-trigger {
  gap: 4px;
  cursor: pointer;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-icon-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-container2 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownLeft;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navbar8-icon10 {
  width: 24px;
  height: 24px;
}
.navbar8-container3 {
  display: flex;
  align-items: center;
  animation-name: rotateInDownRight;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-end;
  animation-duration: 500ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navbar8-icon12 {
  width: 24px;
  height: 24px;
}
.navbar8-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar8-action11 {
  display: flex;
  flex-direction: row;
}
.navbar8-action21 {
  display: flex;
  flex-direction: row;
}
/* Slightly smaller buttons */
.navbar8-action11, .navbar8-action21 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px; /* Reduce padding to make the button smaller */
  font-size: 14px; /* Reduce font size for smaller text */
  line-height: 1.5; /* Ensure vertical alignment of the text */
  border-radius: 5px; /* Keep the border radius the same */
  min-width: 160px; /* Slightly reduce the minimum width */
  height: 40px; /* Reduce the height of the buttons */
  box-sizing: border-box; /* Ensure padding is included in the width/height calculation */
}

/* Styling for the filled button */
.navbar8-action11 {
  background-color: #178a3f;
  color: white;
  border: none;
}

/* Styling for the outline button */
.navbar8-action21 {
  background-color: transparent;
  color: #178a3f;
  border: 2px solid #178a3f;
}

/* Hover effect for both buttons */
.navbar8-action11:hover, .navbar8-action21:hover {
  opacity: 0.9;
}

/* Ensure links inside buttons take up full height and width */
.navbar8-action11 a, .navbar8-action21 a {
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center; /* Ensure text is centered */
}

/* Optional: Adjust font size of text inside buttons to make it look uniform */
.navbar8-action11 span, .navbar8-action21 span {
  font-size: inherit; /* Inherit the font size from the parent button */
  line-height: inherit; /* Inherit line height for consistency */
}


.navbar8-burger-menu {
  display: none;
}
.navbar8-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar8-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar8-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar8-logo {
  height: 3rem;
}
.navbar8-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar8-icon16 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar8-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}
.navbar8-link12 {
  text-decoration: none;
}
.navbar8-link32 {
  text-decoration: none;
}
.navbar8-link4-accordion {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-trigger {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.navbar8-icon-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-container4 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-icon18 {
  width: 24px;
  height: 24px;
}
.navbar8-container5 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-icon20 {
  width: 24px;
  height: 24px;
}
.navbar8-container6 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-halfunit);
  grid-template-columns: 1fr;
}
.navbar8-menu-item1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page1-image1 {
  width: 50px;
  height: 50px;
}
.navbar8-content1 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-page11 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page2-image1 {
  width: 50px;
  height: 50px;
}
.navbar8-content2 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-page21 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page3-image1 {
  width: 50px;
  height: 50px;
}
.navbar8-content3 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-page31 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page4-image1 {
  width: 50px;
  height: 50px;
}
.navbar8-content4 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-page41 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-buttons2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}
.navbar8-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbar8-container7 {
  top: 100%;
  left: 0px;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  animation-name: fadeInDownBig;
  animation-delay: 0s;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navbar8-link5-menu-list {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-oneandhalfunits);
  grid-gap: var(--dl-space-space-halfunit);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.navbar8-menu-item5 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
.navbar8-page1-image2 {
  width: 30px;
  height: 30px;
}
.navbar8-content5 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-page12 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item6 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
.navbar8-page2-image2 {
  width: 30px;
  height: 30px;
}
.navbar8-content6 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-page22 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item7 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
.navbar8-page3-image2 {
  width: 30px;
  height: 30px;
}
.navbar8-content7 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-page32 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-menu-item8 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  text-decoration: none;
}
.navbar8-page4-image2 {
  width: 30px;
  height: 30px;
}
.navbar8-content8 {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-page42 {
  font-style: normal;
  font-weight: 600;
}
.navbar8-container8 {
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-text14 {
  display: inline-block;
}
.navbar8-text15 {
  display: inline-block;
}
.navbar8-text16 {
  display: inline-block;
}
.navbar8-text17 {
  display: inline-block;
}
.navbar8-text18 {
  display: inline-block;
}
.navbar8-text19 {
  display: inline-block;
}
.navbar8-text20 {
  display: inline-block;
}
.navbar8-text21 {
  display: inline-block;
}
.navbar8-text22 {
  display: inline-block;
}
.navbar8-text23 {
  display: inline-block;
}
.navbar8-text24 {
  display: inline-block;
}
.navbar8-text25 {
  display: inline-block;
}
.navbar8-text26 {
  display: inline-block;
}
.navbar8-text27 {
  display: inline-block;
}
@media(max-width: 767px) {
  .navbar8-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar8-desktop-menu {
    display: none;
  }
  .navbar8-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar8-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .navbar8-container6 {
    grid-gap: 0;
  }
  .navbar8-link5-menu-list {
    display: none;
  }
}
@media(max-width: 479px) {
  .navbar8-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar8-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}/* Full-width banner at the top of the page */
.navbar8-banner {
  background-color: #178a3f; /* Dark blue shade */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  position: fixed; /* Keep it fixed at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%; /* Ensure the banner spans full width */
  box-sizing: border-box;
}

/* Sliding text animation */
.navbar8-sliding-text {
  display: flex;
  animation: slide-left 30s infinite linear; /* Slower sliding effect */
  white-space: nowrap;
}

.navbar8-text-slide {
  color: white;
  text-decoration: none;
  padding-right: 20px;
}

/* Adjusted animation for a smoother start */
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}



/* Mobile view adjustments */
@media (max-width: 768px) {
  .navbar8-banner {
    padding: 8px 15px; /* Smaller padding on mobile */
    font-size: 14px; /* Adjust font size for better readability */
  }

  .navbar8-sliding-text {
    animation: slide-left 40s infinite linear; /* Even slower on mobile */
  }


}

html {
  scroll-behavior: smooth;
}

/* Navbar Banner Styles */
.navbar8-banner {
  background-color: #178a3f;
  padding: 10px;
  overflow: hidden;
  width: 100%;
}

.navbar8-sliding-text {
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: scrollBanner 30s ease-in-out infinite; /* Slower with easing */
}

@keyframes scrollBanner {
  0% {
    transform: translateX(100%); /* Start from outside the right */
  }
  30% {
    transform: translateX(0); /* Ease into view slowly */
  }
  100% {
    transform: translateX(-100%); /* Move to the left completely */
  }
}

/* Make sure the banner is responsive */
@media (max-width: 768px) {
  .navbar8-sliding-text {
    font-size: 14px;  /* Adjust text size on mobile */
    padding-left: 20px;
  }
}
/* Navbar Banner Styles */
.navbar8-banner {
  background-color: #178a3f;
  padding: 10px;
  overflow: hidden;
  width: 100%;
}

.navbar8-sliding-text {
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: scrollBanner 30s ease-out infinite; /* Using ease-out to slow down at the end */
}

@keyframes scrollBanner {
  0% {
    transform: translateX(100%); /* Start from outside the right */
  }
  20% {
    transform: translateX(0); /* Begin easing into view earlier */
  }
  100% {
    transform: translateX(-100%); /* Move to the left completely */
  }
}

/* Make sure the banner is responsive */
@media (max-width: 768px) {
  .navbar8-sliding-text {
    font-size: 14px;  /* Adjust text size on mobile */
    padding-left: 20px;
  }
}

.navbar8-image1 {
  position: relative; /* Make sure it's not fixed or absolute */
  width: 160px; /* Slightly increase the size */
  height: auto;
  top: 10px; /* Slightly move it lower */
}
/* Ensure the container has a clean layout */
.navbar8-container {
  display: flex;
  gap: 15px; /* Space between the buttons */
  justify-content: center;
  align-items: center;
  padding: 10px;
}


.navbar {
  display: flex;
  align-items: center;
}

.navbar-btn {
  margin-right: 15px;
  padding: 10px;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icons svg {
  margin-left: 10px;
  cursor: pointer;
}

.social-icons svg:hover {
  color: #003d64; /* Hover effect */
}
