/* General Styling for the Equipment Container */
.equipement-container {
  font-family: Arial, sans-serif;
}

/* Intro Section */
.equipement-intro {
  text-align: center;
  margin: 50px 0;
}

.equipement-intro h1 {
  font-size: 2.5rem;
  line-height: 1.2;
}

.equipement-intro .description {
  font-size: 1.2rem;
  color: #555;
}

/* Equipment List Layout */
.equipments-list {
  padding: 40px 0;
}

.equipments-list ul {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.equipement-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 1200px;
  margin: 20px 0;
  padding: 20px;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.equipement-item:hover {
  transform: translateY(-10px);
}

.equipement-icon {
  max-width: 300px;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 30px;
}

.equipement-info h3 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.equipement-info p {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #444;
  text-align: justify;
}

@media (max-width: 768px) {
  .equipement-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .equipement-icon {
    max-width: 100%;
    margin-bottom: 20px;
  }
}
